import i18n from 'src/plugins/i18n'
import Repositories from 'src/repositories'

export async function login ({ dispatch, commit, getters }, credentials) {
  try {
    const response = await Repositories.me.login(credentials)

    const { accessToken, refreshToken, ...user } = response.data
    commit('SET_USER', user)
    commit('SET_TOKENS', { accessToken, refreshToken })

    const notification = i18n.t('general.welcome', { name: getters.fullname })
    dispatch('ui/showSuccessNotification', notification, { root: true })
    return true
  }
  catch (error) {
    const notification = Repositories.parseErrorMessage(error)
    dispatch('ui/showErrorNotification', notification, { root: true })
    console.error(error)
    return false
  }
}

export async function logout ({ dispatch, commit }) {
  try {
    await Repositories.me.logout()
  }
  catch (error) {
    const notification = Repositories.parseErrorMessage(error)
    dispatch('ui/showErrorNotification', notification, { root: true })
    console.error(error)
  }
  finally {
    // Ensure client logout, even if a server error has occured
    commit('SET_USER', null)
    commit('SET_TOKENS', null)
  }
}

export async function updateMe ({ dispatch, commit, getters }, partialUserData) {
  try {
    await Repositories.me.updateMe(partialUserData)
    // Don't keep the password in the store
    const { password, ...userData } = partialUserData
    commit('SET_USER', { ...getters.user, ...userData })

    const notification = i18n.t('user.accountUpdated')
    dispatch('ui/showSuccessNotification', notification, { root: true })

    return { error: null }
  }
  catch (error) {
    const notification = Repositories.parseErrorMessage(error)
    dispatch('ui/showErrorNotification', notification, { root: true })

    // Utilisateur déjà existant
    return { error: error?.response?.data?.error || 'UnknownError' }
  }
}

export async function setDefaultLanguage ({ commit }, language) {
  commit('SET_DEFAULT_LANGUAGE', language)
}
