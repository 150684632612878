const requireModule = require.context('.', true, /\.js$/)
const modules = {}

requireModule.keys().forEach(fileName => {
  if (fileName === './index.js') return

  // Replace ./ and .js
  const path = fileName.replace(/(\.\/|\.js)/g, '')
  const [moduleName, file] = path.split('/')

  if (file === 'index') {
    modules[moduleName] = requireModule(fileName).default
  }
})

export default modules
