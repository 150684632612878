export default [
  {
    path: '/login',
    component: () => import('src/layouts/EmptyLayout'),
    meta: { requiresGuest: true },
    children: [
      {
        path: '',
        name: 'Login',
        component: () => import('./containers/LoginContainer'),
      },
    ],
  },
]
