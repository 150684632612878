export function user (state) {
  return state.user
}

export function username (state, getters) {
  return getters.user?.username
}

export function roles (state, getters) {
  return getters.user?.roles ?? []
}

export function fullname (state, getters) {
  return getters.user
    && `${getters.user.firstName} ${getters.user.lastName}`
}

export function company (state, getters) {
  return getters.user?.company
}

export function companyName (state, getters) {
  return getters.company?.displayName
}

export function accessToken (state) {
  return state.tokens?.accessToken
}

export function refreshToken (state) {
  return state.tokens?.refreshToken
}

export function isAuthenticated (state, getters) {
  return !!getters.user && !!getters.accessToken
}

export function language (state, getters) {
  return getters?.user?.lang ?? state?.defaultLanguage ?? 'en'
}
