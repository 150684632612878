import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import AppModules from 'src/modules'

import modules from './modules'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    ...modules,
    ...AppModules.getStores(),
  },
  plugins: [createPersistedState({
    key: 'player-ccpm',
    paths: ['me'],
  })],
})
