<template>
  <router-view />
</template>

<script>
import { mapGetters } from 'vuex'
import { setBearerToken } from 'src/services/axios'

export default {
  name: 'App',
  computed: {
    ...mapGetters('me', {
      accessToken: 'accessToken',
      language: 'language',
    }),
  },
  watch: {
    language: {
      immediate: true,
      handler (value) {
        this.$i18n.locale = value
        this.$vuetify.lang.current = value
      },
    },
    accessToken: {
      immediate: true,
      handler (value) {
        setBearerToken(value)
      },
    },
  },
}
</script>
