import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'

import Vue from 'vue'
import App from 'src/App'
import Repositories from 'src/repositories'
import AppModules from 'src/modules'
import router from 'src/router'
import store from 'src/store'
import vuetify from 'src/plugins/vuetify'
import i18n from 'src/plugins/i18n'
import Config from 'src/config.json'

Repositories.registerRepository(AppModules.getRepositories())

Vue.config.productionTip = false
Vue.prototype.$config = Config

const vue = new Vue({
  i18n,
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app')

export default vue
