import Vue from 'vue'
import VueI18n from 'vue-i18n'
import Konami from 'konami'

import fr from 'src/lang/fr'
import en from 'src/lang/en'

Vue.use(VueI18n)

const messages = {
  fr,
  en,
}

const i18n = new VueI18n({
  locale: 'fr', // set locale
  fallbackLocale: 'en', // set fallback locale
  messages, // set locale messages
})

// Enable/disable i18n with Konami inside app
let disabledTranslation = false
const previousSettings = {}
Konami(() => {
  if (disabledTranslation) {
    i18n.fallbackLocale = previousSettings.fallbackLocale
    i18n.locale = previousSettings.locale
    disabledTranslation = false
    i18n.silentFallbackWarn = previousSettings.silentFallbackWarn
    i18n.silentTranslationWarn = previousSettings.silentTranslationWarn
  }
  else {
    previousSettings.fallbackLocale = i18n.fallbackLocale
    previousSettings.locale = i18n.locale
    previousSettings.silentFallbackWarn = i18n.silentFallbackWarn
    previousSettings.silentTranslationWarn = i18n.silentTranslationWarn
    i18n.silentFallbackWarn = true
    i18n.silentTranslationWarn = true
    disabledTranslation = true
    i18n.fallbackLocale = 'none'
    i18n.locale = 'none'
  }
})

export default i18n
