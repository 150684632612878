import { parseErrorMessage } from 'src/services/repository/utils-repository'

const Repositories = {
  parseErrorMessage,
  registerRepository (repositories) {
    Object.assign(Repositories, repositories)
  },
}

export default Repositories
