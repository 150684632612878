import Axios from 'axios'
import Config from 'src/config.json'

let bearerToken = null

const axios = Axios.create({
  baseURL: Config.API_URL,
})

axios.interceptors.request.use(axiosConfig => {
  if (bearerToken) {
    axiosConfig.headers.common.Authorization = `Bearer ${bearerToken}`
  }
  return axiosConfig
}, error => Promise.reject(error))

export function setBearerToken (token) {
  bearerToken = token
}

export default axios
