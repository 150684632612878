import Vue from 'vue'
import Vuetify from 'vuetify/lib'

import fr from 'vuetify/es5/locale/fr'
import { Ripple } from 'vuetify/lib/directives'

Vue.use(Vuetify, {
  directives: {
    Ripple,
  },
})

export default new Vuetify({
  lang: {
    locales: { fr },
    current: 'fr',
  },
  theme: {
    themes: {
      light: {
        primary: '#2D566D',
        secondary: '#576A74',
        accent: '#8A3E70',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FB8C00',
      },
      dark: {
        primary: '#2D566D',
        secondary: '#576A74',
        accent: '#8A3E70',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FB8C00',
      },
    },
  },
})
