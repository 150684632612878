import store from 'src/store'
import { getRouteMeta } from 'src/utils/router'

export function createGuards (router) {
  router.beforeEach((to, from, next) => {
    const { requiresAuth, requiresGuest } = getRouteMeta(to)
    const isAuthenticated = store.getters['me/isAuthenticated']

    if (requiresAuth && !isAuthenticated) {
      return next({ name: 'Login' })
    }

    if (requiresGuest && isAuthenticated) {
      return next({ name: 'Home' })
    }

    return next()
  })
}
