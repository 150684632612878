import i18n from 'src/plugins/i18n'
import CrudRepository from './crud-repository'

function getErrorCode (error) {
  if (error?.message === 'Network Error') {
    return 'NetworkError'
  }

  return error?.response?.data?.error ?? 'UnknownError'
}

export function parseErrorMessage (error) {
  const errorCode = getErrorCode(error)
  return i18n.t(`error.api.${errorCode}`)
}

export function createCrudRepository (resource) {
  return {
    getList: (...args) => CrudRepository.getList(resource, ...args),
    get: (...args) => CrudRepository.get(resource, ...args),
    post: (...args) => CrudRepository.post(resource, ...args),
    patch: (...args) => CrudRepository.patch(resource, ...args),
    delete: (...args) => CrudRepository.delete(resource, ...args),
  }
}

export default {
  parseErrorMessage,
  createCrudRepository,
}
