import BaseRepository from './base-repository'

export default {
  getList: async resource => {
    const response = await BaseRepository.get(`${resource}/`)
    return response?.data ?? response
  },
  get: async (resource, id) => {
    const response = await BaseRepository.get(`${resource}/${id}`)
    return response?.data ?? response
  },
  post: async (resource, data) => {
    const response = await BaseRepository.post(`${resource}/`, data)
    return response?.data ?? response
  },
  patch: async (resource, { id, ...data }) => {
    const response = await BaseRepository.patch(`${resource}/${id}`, data)
    return response?.data ?? response
  },
  delete: async (resource, idList) => {
    const response = await BaseRepository.deleteMany(`${resource}/`, idList)
    return response?.data ?? response
  },
}
