export default [
  {
    path: '/',
    component: () => import('src/layouts/DefaultLayout'),
    meta: { requiresAuth: true },
    children: [
      {
        path: '',
        name: 'Home',
        component: () => import('./containers/HomeContainer'),
      },
    ],
  },
]
