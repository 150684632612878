const requireModule = require.context('.', true, /\.js$/)
const AppModules = {}

requireModule.keys().forEach(fileName => {
  if (fileName === './index.js') return

  // Replace ./ and .js
  const path = fileName.replace(/(\.\/|\.js)/g, '')
  const [moduleName, file] = path.split('/')

  if (file === 'index') {
    AppModules[moduleName] = requireModule(fileName).default
  }
})

function getRoutes () {
  return Object.values(AppModules)
    .reduce((routes, module) => ([...routes, ...(module.routes || [])]), [])
}

function getStores () {
  return Object.values(AppModules)
    .reduce((stores, module) => ({ ...stores, ...module.stores }), {})
}

function getRepositories () {
  return Object.values(AppModules)
    .reduce((repositories, module) => ({ ...repositories, ...module.repositories }), {})
}

export default {
  getRoutes,
  getStores,
  getRepositories,
}
