import RepositoryService from 'src/services/repository'

const ressource = 'trainingSession'

function login (credentials) {
  return RepositoryService.base.post(`${ressource}/login`, credentials)
}

function logout () {
  return RepositoryService.base.post(`${ressource}/logout`)
}

export default {
  login,
  logout,
}
