export default {
  general: {
    name: 'CCPM Games',
    yes: 'Oui',
    no: 'Non',
    add: 'Ajouter',
    update: 'Modifier',
    close: 'Fermer',
    cancel: 'Annuler',
    create: 'Créer',
    remove: 'Supprimer',
    confirmation: 'Confirmation',
    search: 'Recherche',
    language: 'Langue',
    validate: 'Valider',
    connect: 'Connect',
    welcome: 'Bienvenue {name}',
    loading: 'Chargement en cours... Veuillez patienter',
    loadingShort: 'Chargement...',
    empty: 'Vide',
    actions: 'Actions',
  },
  date: {
    date: 'dd/MM/yyyy',
    time: 'HH:mm',
    dateAndTime: 'dd/MM/yyyy à HH:mm',
  },
  language: {
    french: 'Français',
    english: 'Anglais',
  },
  menu: {},
  title: {
    login: 'CCPM Games',
    home: 'Accueil',
  },
  color: {
    red: 'Rouge',
    pink: 'Rose',
    purple: 'Violet',
    'deep-purple': 'Violet foncé',
    indigo: 'Indigo',
    blue: 'Bleu',
    'light-blue': 'Bleu clair',
    cyan: 'Cyan',
    teal: 'Teal',
    green: 'Vert',
    'light-green': 'Vert clair',
    lime: 'Citron',
    yellow: 'Jaune',
    amber: 'Ambré',
    orange: 'Orange',
    'deep-orange': 'Orange foncé',
    brown: 'Marron',
    'blue-grey': 'Bleu gris',
    grey: 'Gris',
  },
  error: {
    form: {
      required: 'Le champ est requis',
      minLength: 'Le champ doit contenir au moins {length} caractères',
      maxLength: 'Le champ ne doit pas contenir plus de {length} caractères',
      exactLength: 'Le champ doit faire exactement {length} caractères',
      noSpaces: 'Le champ ne doit pas contenir d\'espace',
      noEqualPassword: 'Les mots de passe ne sont pas identiques',
      userExist: 'L\'identifiant existe déjà dans votre organisation',
      alpha: 'Le champ ne peut contenir que des lettres',
      alphanumeric: 'Le champ ne peut contenir que des lettres et des chiffres',
      greater: 'Le nombre doit être supérieur à {number}',
      lower: 'Le nombre doit être inférieur à {number}',
      email: 'L\'adresse e-mail n\'est pas valide',
    },
  },
  login: {
    fields: {
      sessionCode: 'Session code',
      playerCode: 'Participant code',
      playerName: 'Full name',
      playerEmail: 'E-mail',
    },
    formrules: {
      playerCodeFormat: 'Le champ doit être au format A00',
    },
  },
}
