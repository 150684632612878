import Vue from 'vue'
import VueRouter from 'vue-router'
import AppModules from 'src/modules'
import { createGuards } from './guards'

Vue.use(VueRouter)

const router = new VueRouter({
  routes: [...AppModules.getRoutes()],
})

createGuards(router)

export default router
