import axios from 'src/services/axios'

function get (url, data) {
  return axios.get(url, { params: data })
}

function post (url, data) {
  return axios.post(url, data)
}

function patch (url, data) {
  return axios.patch(url, data)
}

function deleteMany (url, idList) {
  // ! Axios delete function ignore data in config object
  // ! Waiting for next axios release (> 0.20.0)
  // TODO: use standard delete when next release is published
  axios.request({
    url,
    method: 'delete',
    data: { idList },
  })
  // return axios.delete(url, { data: { idList } })
}

export default {
  get,
  post,
  patch,
  deleteMany,
}
